"use client";

import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import useTheme from "@mui/material/styles/useTheme";
// LOCAL CUSTOM COMPONENT

import BannerCard from "../banner-card";
// GLOBAL CUSTOM COMPONENTS

import { NavLink3 } from "@/components/nav-link";
import { Carousel } from "@/components/carousel";
import { H4, Paragraph } from "@/components/Typography";
import CarouselCard4 from "@/components/carousel-cards/carousel-card-4";
import { COMMON_DOT_STYLES } from "@/components/carousel/styles";
// CUSTOM DATA MODEL


// ======================================================
export default function Section1({
  carouselData
}) {
  const {
    palette
  } = useTheme();
  return <div className="pt-2">
      <Container>
        <Grid container spacing={2}>
          {
          /* MAIN CAROUSEL AREA */
        }
          <Grid item md={9} xs={12}>
            <Carousel dots arrows={false} spaceBetween={0} slidesToShow={1} dotColor={palette.dark.main} dotStyles={COMMON_DOT_STYLES}>
              {carouselData.map((item, ind) => <CarouselCard4 key={ind} mode="light" title={item.title} bgImage={item.imgUrl} discount={item.discount} category={item.category} buttonLink={item.buttonLink} buttonText={item.buttonText} description={item.description} />)}
            </Carousel>
          </Grid>

          <Grid item md={3} xs={12}>
            <Stack spacing={2} height="100%" direction={{
            md: "column",
            sm: "row",
            xs: "column"
          }}>

              <BannerCard imageFull flex={1} img="/storage/assets/images/banners/banner-17.jpg" maxHeight={240}>
                <Paragraph fontSize={13} letterSpacing={1.2}>
                  NEW ARRIVALS
                </Paragraph>

                <H4 fontSize={20} lineHeight={1.2} mb={2}>
                  BAG TO
                  <br />
                  SCHOOL
                </H4>

                <NavLink3 href="/categories/bag-accessories" text="Shop Now" color="dark.main" />
              </BannerCard>

              <BannerCard imageFull flex={1} img="/storage/assets/images/banners/banner-16.jpg" maxHeight={240}>
                <Paragraph fontSize={13} letterSpacing={1.2}>
                  Footwear
                </Paragraph>

                <H4 fontSize={20} lineHeight={1.2} mb={2}>
                  SCHOOL
                  <br />
                  SHOES
                </H4>

                <NavLink3 href="/categories/shoes-socks" text="Shop Now" color="dark.main" />
              </BannerCard>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </div>;
}
